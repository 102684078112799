<template>
    <div
        class="pa-4 d-flex justify-space-between"
    >
        <v-btn
            color="white"
            depressed
        >
            upbit
        </v-btn>
    </div>
</template>

<script>
export default {
    data: () => ({
        
    }),

    methods: {

    }
}

</script>