<template>
    <div>
        <!-- 본문 -->
        <div
            class="pt-6"
            style="position:relative;"
        >
            <v-icon
                style="position:absolute;"
                :style="$vuetify.breakpoint.xsOnly? 'top:7px; right:7px;':'top:10px; right:10px;'"
                size="24"
                color="#667080"
                @click="$router.push('/')"
            >
                mdi-close
            </v-icon>

            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.xsOnly? '94%':400"
            >
                <!-- 로고 -->
                <div
                    class="mb-10 d-flex justify-center"
                >
                    <div
                        class="pa-2 primary rounded-10"
                    >
                        <v-img
                            height="80"
                            width="80"
                            contain
                            src="@/assets/logo/default.png"
                        ></v-img>
                    </div>
                </div>

                <v-sheet
                    :width="$vuetify.breakpoint.xsOnly? '94%':280"
                    class="mx-auto"
                >
                    <!-- 아이디 -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        autofocus
                        :placeholder="$store.state.language == 'English' ? 'ID' : '아이디'"
                        v-model="user_id"
                        @change="$v.user_id.$touch()"
                        @blur="$v.user_id.$touch()"
                        :error-messages="user_id_Errors"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>

                    <!-- 비밀번호 -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        :type="showPassword ? 'text' : 'password'"
		                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :placeholder="$store.state.language == 'English' ? 'Password' : '비밀번호'"
                        v-model="password"
                        @change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        :error-messages="password_Errors"
                        @click:append="togglePasswordVisibility('password')"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>

                    <!-- 로그인 버튼 -->
                    <v-btn
                        class="font-weight-bold"
                        color="primary"
                        block
                        dark
                        large
                        depressed
                        @click="submit()"
                    >
                        {{ $store.state.language == 'English' ? 'Log in' : '로그인' }}
                    </v-btn>

                    <!--<!~~ SNS ~~>
                    <v-sheet class="my-4 mx-2 d-flex align-center justify-center">
                        <v-divider class="grey lighten-2"></v-divider>
                        <p class="my-0 mx-4 text-caption grey--text">소셜미디어 계정으로 로그인</p>
                        <v-divider class="grey lighten-2"></v-divider>
                    </v-sheet>

                    <v-btn
                        class="font-weight-regular"
                        block
                        color="grey"
                        large
                        outlined
                        depressed
                        @click="googleLogin()"
                    >
                        <div
                            width="100%"
                            class="d-flex align-center black--text text-caption"
                        >
                            <v-img
                                class="mr-2"
                                height="20"
                                width="20"
                                contain
                                src="@/assets/google.png"
                            ></v-img>
                            <span>Google 로그인</span>
                        </div>
                    </v-btn>

                    <!~~ 네이버 ~~>
                       <v-sheet
                            height="42"
                            style="cursor:pointer"
                            @click="social_naver()"
                            class="mt-3 rounded-xl"
                        >
                            <v-img
                                height="42"
                                src="@/assets/auth/login/social/naver.png"
                            ></v-img>
                        </v-sheet>

                        <div class="mt-3">
                            <div id="naver_id_login"></div>
                        </div>

                        <!~~ 카카오 ~~>
                        <v-sheet
                            height="42"
                            style="cursor:pointer"
                            @click="social_kakao()"
                            class="mt-3 rounded-xl"
                        >
                            <v-img
                                height="42"
                                src="@/assets/auth/login/social/kakao.png"
                            ></v-img>
                        </v-sheet>-->
                </v-sheet>

                <!-- 버튼 -->
                <div class="d-flex align-center justify-center mt-4">
                    <v-btn
                        :width="$store.state.language == 'English'? 70:70"
                        color="grey darken-1"
                        :class="$store.state.language == 'English'? 'text-capitalize':''"
                        small
                        text
                        to="/auth/find/id"
                    >
                        {{ $store.state.language == 'English' ? 'Forgot ID?' : '아이디 찾기' }}
                    </v-btn>
                    <div class="mx-3 mt-1" style="width:1px; height:14px; background:#ccc;"></div>
                    <v-btn
                        :width="$store.state.language == 'English'? 70:76"
                        :class="{
                            'mx-2': !$vuetify.breakpoint.xsOnly,
                            'text-capitalize': $store.state.language == 'English'
                        }"
                        color="grey darken-1"
                        small
                        text
                        to="/auth/find/password"
                    >
                        {{ $store.state.language == 'English' ? 'Forgot PW?' : '비밀번호 찾기' }}
                    </v-btn>
                    <div class="mx-3 mt-1" style="width:1px; height:14px; background:#ccc;"></div>
                    <v-btn
                        :width="$store.state.language == 'English'? 62:60"
                        color="grey darken-1"
                        :class="$store.state.language == 'English'? 'text-capitalize':'mr-2px'"
                        small
                        text
                        to="/auth/join/agree"
                    >
                        {{ $store.state.language == 'English' ? 'Sign Up' : '회원가입' }}
                    </v-btn>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
// 검증
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required
        },
        password: {
            required
        }
    },

    data: () => ({
        user_id: "",
        password: "",
        showPassword: false
    }),

    computed: {
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push(this.$store.state.language == 'English' ? 'Please enter your ID' : '아이디를 입력해주세요')
            return errors
        },

        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push(this.$store.state.language == 'English' ? 'Please enter your password' : '비밀번호를 입력해주세요')
            return errors
        }
    },

    methods: {
        togglePasswordVisibility(field) {
			this.showPassword = !this.showPassword
		},

        ...mapMutations(['login']),

        submit(){
            this.$v.$touch()

            if (!this.$v.$invalid) {
                // 아이디 & 비밀번호 확인
                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.user_id,
                        password:this.password
                    }
                }).then((res) => {
                    // 일치하지 않을 시
                    if(!res.data.length){
                        // 임시 비밀번호 확인
                        this.$http.post("/api/etc/temp_password/select/check", {
                            params: {
                                user_id: this.user_id,
                                password: this.password
                            }
                        }).then((res) => {
                            if(!res.data.length){
                                this.$globalSnackbar.show(this.$store.state.language == 'English' ? 'ID or password is incorrect' : '아이디 또는 비밀번호가 잘못되었습니다.')
                            }else{
                                // 이메일 인증여부 확인 & 로그인
                                this.emailCheckAndLogin(res)
                            }
                        })
                    // 일치 시
                    }else{
                        // 이메일 인증여부 확인 & 로그인
                        this.emailCheckAndLogin(res)
                    }
                })
            }
        },

        // 이메일 인증여부 확인 & 로그인
        emailCheckAndLogin(res){
            this.$http.post("/api/user/select/specific", {
                params: {
                    user_id: this.user_id
                }
            }).then((res2) => {
                // # 인증 이메일 없는 버전
                this.login(res2.data[0])
                this.$router.push("/")
                
                // # 인증 이메일 있는 버전
                // // 이메일 인증이 안되었을 시
                // if(res2.data[0].status != "인증완료"){
                //     // 이메일 주소 마스킹
                //     const email = res2.data[0].email
                //     const maskedEmail = email.replace(/^(.{2})(.*)(@.*)$/, (match, p1, p2, p3) => {
                //         return p1 + '*'.repeat(p2.length) + p3
                //     })
                    

                //     this.$globalSnackbar.show(this.$store.state.language == 'English' ? 'The email has not been verified.\n'+maskedEmail+" was sent again.\nPlease log in after completing the verification." : "이메일 인증이 완료되지 않은 아이디입니다.\n"+maskedEmail+"을 다시 발송드렸습니다. \n인증완료 후 로그인해주세요.")

                //     // 인증 이메일 발송
                //     this.$http.post('/api/email/send/email_certification', {
                //         params: {
                //             user_id: this.user_id,
                //             email: res2.data[0].email,
                //             language: this.$store.state.language
                //         }
                //     })
                // }
                // // 이메일 인증이 되었을 시
                // else{
                //     // 로그인
                //     this.login(res.data[0])
                //     this.$router.push("/")
                // }
            })
        },

        // 소셜로그인 > Google
        googleLogin() {
            const clientId = '431163131298-pvo7r3ct3jc1bvimpb4932t8930q28vn.apps.googleusercontent.com'
            const redirectUri = 'http://localhost:8081/auth/sns/google'
            const scope = 'email https://www.googleapis.com/auth/userinfo.profile'
            const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`

            window.location = authUrl
        }
    }
}
</script>